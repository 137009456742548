import { ThemeUIStyleObject } from 'theme-ui';

export enum CardVariant {
  TITLE_MEDIA_CONTENT = 'TITLE_MEDIA_CONTENT',
  TOP_CONTENT_BOTTOM_MEDIA = 'TOP_CONTENT_BOTTOM_MEDIA',
  LEFT_CONTENT_RIGHT_MEDIA = 'LEFT_CONTENT_RIGHT_MEDIA',
  MEDIA_CONTENT = 'MEDIA_CONTENT',
}

export const CARD_VARIANT_TO_GRID_STYLE: Record<CardVariant, ThemeUIStyleObject> = {
  [CardVariant.TITLE_MEDIA_CONTENT]: {
    rowGap: ['32px', null, '60px'],
  },
  [CardVariant.TOP_CONTENT_BOTTOM_MEDIA]: {
    columnGap: '76px',
    rowGap: '64px',
  },
  [CardVariant.LEFT_CONTENT_RIGHT_MEDIA]: {
    rowGap: ['32px', '40px', '86px'],
  },
};
