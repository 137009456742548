import React, { useMemo } from 'react';

import { Grid, Flex } from 'theme-ui';
import { TitleMediaContentCard } from './components/titleMediaContent';
import { TopContentBottomMedia } from './components/topContentBottomMedia';
import { LeftContentRightMediaCard } from './components/leftContentRightMedia';
import { MediaContent } from './components/mediaContent';
import { Title } from '@sprinklr/shared-lib/components/Title';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

import { ContentMediaGridProps as ContentMediaGridProps } from './types';

import { CardVariant, CARD_VARIANT_TO_GRID_STYLE } from './constants';

const VARIANT_VS_COMPONENT = {
  [CardVariant.TITLE_MEDIA_CONTENT]: TitleMediaContentCard,
  [CardVariant.TOP_CONTENT_BOTTOM_MEDIA]: TopContentBottomMedia,
  [CardVariant.LEFT_CONTENT_RIGHT_MEDIA]: LeftContentRightMediaCard,
  [CardVariant.MEDIA_CONTENT]: MediaContent,
};

const ContentMediaGrid = (props: ContentMediaGridProps) => {
  const {
    cols,
    items,
    cardVariant,
    title,
    description,
    icon,
    topPadding,
    bottomPadding,
  } = props;

  const [pt, pb] = useContainerPadding({
    top: topPadding ?? 'NONE',
    bottom: bottomPadding ?? 'NONE',
  });

  const Component = VARIANT_VS_COMPONENT[cardVariant] ?? TitleMediaContentCard;

  return (
    <Flex
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'column',
        gap: ['20px', '32px', '72px'],
        pt,
        pb,
      }}
    >
      <Title title={title} icon={icon} description={description} />
      <Grid
        columns={[1, 1, cols]}
        sx={{
          columnGap: '60px',
          rowGap: '60px',
          justifyContent: 'center',
          justifyItems: 'center',
          ...(CARD_VARIANT_TO_GRID_STYLE[cardVariant] ?? {}),
        }}
      >
        {items.map(item => (
          <Component {...item} key={item.id} />
        ))}
      </Grid>
    </Flex>
  );
};

export { ContentMediaGrid };
