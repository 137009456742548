import React, { ReactElement } from 'react';

//components
import { Flex, Text, Box } from 'theme-ui';
import Media from '@sprinklr/shared-lib/components/media';

import { ContentCardProps } from '../../types';

const TitleMediaContentCard = ({
  title,
  tag,
  desc,
  media,
}: ContentCardProps): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'rgba(16, 126, 255,0.1)',
          borderRadius: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '0 0 auto',
          paddingY: '8px',
          paddingX: '12px',
        }}
      >
        <Text
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#107EFF',
            flex: '0 0 auto',
          }}
          as="h3"
        >
          {tag}
        </Text>
      </Flex>
      <Text
        sx={{
          flex: '0 0 auto',
          fontWeight: 700,
          fontSize: ['20px', null, '28px'],
          lineHeight: ['24px', null, '36px'],
          textAlign: 'center',
          marginTop: '8px',
        }}
        as="h4"
      >
        {title}
      </Text>
      <Flex
        sx={{
          flex: '1 1 0%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '16px',
          width: '100%',
        }}
      >
        <Media
          {...media}
          source={media?.asset}
          mediaSx={{ marginTop: [0, 0, 0], marginBottom: [0, 0, 0] }}
        />
      </Flex>
      <Text
        sx={{
          flex: '0 0 auto',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          color: 'sprText02',
          textAlign: 'center',
          marginTop: ['24px', null, '40px'],
        }}
      >
        {desc}
      </Text>
    </Box>
  );
};

export { TitleMediaContentCard };
