import React from 'react';

import { Box, Text } from 'theme-ui';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import Media from '@sprinklr/shared-lib/components/media';

import { ContentCardProps } from '../../types';
import {
  CONTAINER_STYLES,
  DESCRIPTION_STYLES,
  ICON_CONTAINER_STYLES,
  ICON_OVERLAY_STYLES,
  KEYPOINT_TEXT_STYLES,
  TITLE_STYLES,
} from './styles';

export const Card = ({ title, desc, media, keyPoints }: ContentCardProps) => {
  return (
    <Box sx={CONTAINER_STYLES}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexBasis: 0,
          flexGrow: 2,
          justifyContent: 'center',
        }}
      >
        <Text sx={TITLE_STYLES}>{title}</Text>

        <Text sx={DESCRIPTION_STYLES}>{desc}</Text>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: ['8px', null, '16px'],
          }}
        >
          {keyPoints.map(keyPoint => {
            return (
              <Box sx={{ display: 'flex', gap: '8px' }} key={keyPoint}>
                <Box sx={ICON_CONTAINER_STYLES}>
                  <Box sx={ICON_OVERLAY_STYLES} />
                  <Icon
                    iconSx={{ height: '14px', width: '14px', fill: 'white' }}
                    iconName="tick"
                  />
                </Box>
                <Text sx={KEYPOINT_TEXT_STYLES}>{keyPoint}</Text>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flexBasis: 0,
          flexGrow: 3,
        }}
      >
        <Media
          {...media}
          source={media?.asset}
          mediaSx={{ marginTop: '0px', marginBottom: '0px' }}
        />
      </Box>
    </Box>
  );
};
