// libs
import React, { ReactElement } from 'react';

import Media from '@sprinklr/shared-lib/components/media';

// types
import { ContentCardProps } from '../../types';

export const MediaContent = (props: ContentCardProps): ReactElement => {
  const { media } = props;
  return (
    <Media
      {...media}
      source={media?.asset}
      mediaSx={{
        marginTop: ['0px', '0px', '0px'],
        marginBottom: ['0px', '0px', '0px'],
      }}
    />
  );
};
