// libs
import React, { ReactElement } from 'react';

// components
import { Flex, Text } from 'theme-ui';
import Media from '@sprinklr/shared-lib/components/media';

// types
import { ContentCardProps } from '../../types';

export const TopContentBottomMedia = (
  props: ContentCardProps,
): ReactElement => {
  const { tag, title, desc, media } = props;

  return (
    <Flex sx={{ flexDirection: 'column', gap: '28px', width: '100%' }}>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          textAlign: 'center',
        }}
      >
        {tag ? (
          <Text
            sx={{
              fontSize: ['15px', '16px'],
              fontWeight: 600,
              lineHeight: '20px',
              color: 'primary',
            }}
            as="h3"
          >
            {tag}
          </Text>
        ) : null}
        <Text
          sx={{
            fontSize: ['20px', '24px', '32px'],
            fontWeight: 700,
            lineHeight: ['24px', '30px', '38px'],
          }}
          as="h4"
        >
          {title}
        </Text>
        {desc ? (
          <Text
            sx={{
              fontSize: 2,
              lineHeight: ['22px', '26px'],
              color: 'textMedium',
            }}
          >
            {desc}
          </Text>
        ) : null}
      </Flex>
      <Media
        {...media}
        source={media?.asset}
        mediaSx={{
          marginTop: ['0px', '0px', '0px'],
          marginBottom: ['0px', '0px', '0px'],
        }}
      />
    </Flex>
  );
};
