import { ThemeUIStyleObject } from 'theme-ui';

export const TITLE_STYLES: ThemeUIStyleObject = {
  fontSize: ['20px', '24px', '32px'],
  lineHeight: ['24px', '30px', '38px'],
  fontWeight: 700,
  marginBottom: '8px',
  letterSpacing: '0.05em',
  textAlign: ['center', null, 'left'],
};

export const DESCRIPTION_STYLES: ThemeUIStyleObject = {
  fontSize: ['15px', '16px', '16px'],
  lineHeight: ['22px', '25px', '26px'],
  fontWeight: 400,
  color: 'sprText02',
  marginBottom: '16px',
  letterSpacing: '0.05em',
  textAlign: ['center', null, 'left'],
};

export const KEYPOINT_TEXT_STYLES: ThemeUIStyleObject = {
  color: 'sprText02',
  fontSize: ['13px', '14px', '14px'],
  lineHeight: '22px',
  fontWeight: 400,
  letterSpacing: '0.05em',
};

export const ICON_CONTAINER_STYLES: ThemeUIStyleObject = {
  height: '18px',
  width: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  minWidth: '18px',
  marginTop: '2px',
};

export const ICON_OVERLAY_STYLES: ThemeUIStyleObject = {
  position: 'absolute',
  height: '18px',
  width: '18px',
  borderRadius: '100%',
  backgroundColor: '#107EFF',
  opacity: 0.1,
};

export const CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  gap: ['24px', null, '52px'],
  flexDirection: ['column', null, 'row'],
  width: '100%',
};
